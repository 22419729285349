import React, { useEffect, useState } from "react";
import { Row, Col, Card, Form, Input, Button, message } from "antd";
import { InputPassword, InputPlug } from "./style";
import {
  useOutletContext,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { apiLogIn, apiForgetPsd } from "api/api";

const LogIn = () => {
  const [info, success, warning, error] = useOutletContext(); // 從outlet傳過來的, 必須整個陣列帶入
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const fromRegister = searchParams.get("fromRegister");

  const [isLoading, setIsLoading] = useState(false);
  const [isForgetLoading, setIsForgetLoading] = useState(false);

  const fetchLogIn = async (data) => {
    setIsLoading(true);
    try {
      const res = await apiLogIn(data);
      const { token } = res.data.content;
      sessionStorage._skyFilm_t = token;
      message.success("登入成功");
      checkNavigate();
    } catch (error) {
      // console.log("err", error.response);
      message.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const checkNavigate = () => {
    setTimeout(() => {
      navigate(-1);
      setTimeout(() => {
        navigate(0);
      }, 50);
    }, 1200);
  };

  const forgetPsd = async () => {
    setIsForgetLoading(true);
    const email = form.getFieldValue("email");
    try {
      if (email.length > 0) {
        const res = await apiForgetPsd({ email });
        const { content } = res.data;
        message.success("已經發送驗證碼至您的信箱");
        sessionStorage.k = content;
        setTimeout(() => {
          navigate("/confirm-otp");
        }, 1500);
      } else {
        message.error("請先輸入email");
      }
    } catch (error) {
      message.error(error.response.data.message);
    } finally {
      setIsForgetLoading(false);
    }
  };

  const onFinish = (values) => {
    // localStorage.isLogin = true;
    fetchLogIn(values);

    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    error();
    console.log("Failed:", errorInfo);
  };

  return (
    <Row className="min-h-[72vh]">
      <Col
        lg={{ span: 8, offset: 8 }}
        sm={{ span: 14, offset: 5 }}
        xs={{ span: 24 }}
        className="pt-10 px-2 md:px-0"
      >
        <Card
          title={<h4 className="text-left">登入Login</h4>}
          className="rounded-2xl px-5 mobile:[&_.ant-card-head-wrapper]:block"
          extra={
            <p className="mobile:mb-4">
              還不是會員?
              <a href="/register" className="text-SKF-Blue-001 ml-1">
                這邊註冊Sign up
              </a>
            </p>
          }
        >
          <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            form={form}
          >
            <Form.Item
              label="Email"
              name="email"
              placeholder="Email"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputPlug size="large" className="p-2" />
            </Form.Item>

            <Form.Item
              label="密碼 Password"
              name="password"
              placeholder="密碼 Password"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputPassword size="large" className="p-2" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="bg-SKF-B-001 w-full"
                loading={isLoading}
              >
                登入
              </Button>
            </Form.Item>
            <p className="text-SKF-Blue-001 text-left">
              <Button
                type="link"
                onClick={forgetPsd}
                className="mobile:p-0 pl-0 text-left text-SKF-Blue-001"
                loading={isForgetLoading}
                disabled={isForgetLoading}
              >
                忘記你的密碼？Forgot your password?
              </Button>
            </p>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default LogIn;
