import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Input,
  Spin,
  message,
  Card,
  Table,
  Modal,
  QRCode,
  Space,
} from "antd";
import {
  apiGetMember,
  apiPatchMember,
  apiResetPsd,
  apiGetOrder,
  apiGetOrderDetail,
} from "api/api";
import { useNavigate } from "react-router-dom";
import { convertTimeStamp } from "utility/functions/convertTimestamp";

import keyBy from "lodash/keyBy";

const Member = () => {
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const isLogIn =
    sessionStorage._skyFilm_t && sessionStorage._skyFilm_t.length > 0;

  const [isLoading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false); // 是否為手機版&平板，是：訂單中表格改為下拉展開顯示
  const [isCardLoading, setIsCardLoading] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [record, setRecord] = useState({});

  // # 檢視更多
  const [isModalOpen, setIsModalOpen] = useState(false);

  //表單是否變動
  const [isFormChange, setIsFormChange] = useState(false);
  const [formData, setFormData] = useState({});

  // # 取得會員資訊
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await apiGetMember();
      const { content } = res.data;
      form.setFieldsValue(content);
      setFormData(content);
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }
    if (isLogIn) fetchData();
    else navigate("/login");
  }, []);

  // # 取得付款資訊
  const fetchOrder = async () => {
    setIsCardLoading(true);
    try {
      const res = await apiGetOrder();
      console.log("付款資訊", res);
      const { content } = res.data;
      setOrders(content);
    } catch (err) {
      console.log("error", err);
    } finally {
      setIsCardLoading(false);
    }
  };

  useEffect(() => {
    fetchOrder();
  }, []);

  // # 取得票券詳情
  const fetchDetail = async (record) => {
    try {
      const res = await apiGetOrderDetail(record.id);
      console.log("rrrr", res);
      const { content } = res.data;
      setRecord(content);
      setIsModalOpen(true);
    } catch (err) {
      console.log("err", err);
    } finally {
    }
  };

  const updateInfo = async (data) => {
    setIsEditLoading(true);
    try {
      const res = await apiPatchMember(data);
    } catch (err) {
    } finally {
      setIsEditLoading(false);
    }
  };

  const onFinish = (values) => {
    console.log("Success12:", values);
    updateInfo(values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const logOut = () => {
    sessionStorage.removeItem("_skyFilm_t");
    fetchData();
    setTimeout(() => {
      navigate("/home");
      navigate(0);
    }, 500);
  };

  // # 觀察表單是否有變動
  const onValuesChange = (current, all) => {
    console.log("now", current);
    console.log("all", all);
    setIsFormChange(true);
  };

  const resetPsd = async () => {
    try {
      const res = await apiResetPsd();
      const { content } = res.data;
      message.success("已經發送驗證碼至您的信箱");
      sessionStorage.k = content;
      setTimeout(() => {
        navigate("/confirm-otp");
      }, 1500);
    } catch (err) {
      console.log("err", err);
    }
  };

  // # 關閉彈窗
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // # 訂單狀態
  const orderStatus = [
    {
      value: 0,
      label: "",
    },
    {
      value: 1,
      label: "訂單失敗",
    },
    {
      value: 2,
      label: "訂單待確認",
    },
    {
      value: 4,
      label: "訂單已付款",
    },
    {
      value: 8,
      label: "整筆訂單退款",
    },
    {
      value: 16,
      label: "部分訂單退款",
    },
    {
      value: 32,
      label: "建立失敗",
    },
  ];

  // # 付款狀態
  const paymentStatus = [
    {
      value: 0,
      label: "",
    },
    {
      value: 1,
      label: "訂單未付款",
    },
    {
      value: 2,
      label: "訂單待確認",
    },
    {
      value: 4,
      label: "訂單已付款",
    },
    {
      value: 8,
      label: "整筆訂單退款",
    },
    {
      value: 16,
      label: "部分訂單退款",
    },
    {
      value: 32,
      label: "建立失敗",
    },
  ];

  // # columns
  const columns = [
    {
      title: "狀態",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <span
          className={`text-white rounded-md px-2 ${
            text == 4 ? "bg-green-500" : "bg-red-500"
          }`}
        >
          {paymentStatus.find((item) => item.value == text)?.label}
        </span>
      ),
    },
    {
      title: "票號",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "價格",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "票券名稱",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "參加者",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "操作",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <Button
          type="link"
          className="text-left decoration-solid p-0"
          onClick={() => fetchDetail(record)}
        >
          詳情
        </Button>
      ),
    },
  ];

  const columns_mobile = [
    {
      title: "狀態 | 票號",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <>
          <span
            className={`text-white rounded-md px-2 ${
              record.status == 4 ? "bg-green-500" : "bg-red-500"
            }`}
          >
            {paymentStatus.find((item) => item.value == record.status)?.label}
          </span>
          <p>{text}</p>
        </>
      ),
    },
    {
      title: "操作",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <Button
          type="link"
          className="text-left decoration-solid p-0"
          onClick={() => fetchDetail(record)}
        >
          詳情
        </Button>
      ),
    },
  ];

  return (
    <div className="w-full px-4 desktop:px-56 bg-white py-20">
      <Spin spinning={isLoading} size="large" fullscreen />
      <Row className="mb-20">
        <Col
          sm={{ span: 18 }}
          xs={{ span: 12 }}
          className="text-black text-left"
        >
          <h3 className="m-0">Hello {formData?.name}</h3>
        </Col>
        <Col
          sm={{ span: 6 }}
          xs={{ span: 12 }}
          className="text-black text-right"
        >
          {isLogIn && (
            <Button className="bg-black text-white px-2" onClick={logOut}>
              登出Log out
            </Button>
          )}
        </Col>
      </Row>

      {/* 基本資料 */}
      <Row>
        <Col span={20}>
          <h4 className="m-0 text-left text-black">基本資料 Information</h4>
        </Col>
        {/* <Col span={4} className="text-right">
          <Button className="bg-SKF-G-001 ">Save</Button>
        </Col> */}
      </Row>

      <Form
        name="member"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
        layout="vertical"
        className="relative"
        initialValues={formData}
        onValuesChange={onValuesChange}
      >
        <Row gutter={16}>
          <Col sm={{ span: 24 }} xs={{ span: 24 }} className="mt-6">
            <Form.Item label="Name" name="name">
              <Input placeholder="名字" className="p-2" />
            </Form.Item>
          </Col>
          {/* <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item label="Last Name" name="last_name">
              <Input placeholder="姓氏" className="p-2" />
            </Form.Item>
          </Col> */}
          <Col span={24}>
            <Form.Item
              label="Email"
              name="email"
              tooltip="若修改，登入將使用更改後的Email"
            >
              <Input placeholder="Email" className="p-2" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Mobile Phone" name="phone" className="m-0">
              <Input placeholder="電話" className="p-2" />
            </Form.Item>
          </Col>
          <Col span={24} className="text-left absolute top-[-1rem] right-0">
            <Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                loading={isEditLoading}
                disabled={!isFormChange}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <div className="text-left">
          <Button
            type="link"
            className="p-0 text-SKF-Blue-001"
            onClick={resetPsd}
          >
            重新設定密碼 Reset your password
          </Button>
        </div>
        <div className="mt-8">
          <h4 className="m-0 text-left text-black">消費紀錄 Information</h4>
          <p className="text-SKF-Blue-001 text-base text-left my-2">
            <a href="mailto:support@rftexp.com">
              票務問題請聯繫：support@rftexp.com
            </a>
          </p>
          {orders &&
            orders.map((order) => {
              return (
                <Card
                  title={
                    <Space className="justify-between w-full text-white">
                      {order.title}
                      <p>NT＄{order.price}</p>
                    </Space>
                  }
                  loading={isCardLoading}
                  className="my-2 text-left [&_.ant-card-head]:bg-black"
                >
                  <Row className="mb-4">
                    <Col
                      // sm={{ span: 12 }}
                      // xs={{ span: 18 }}
                      span={24}
                      className="text-left"
                    >
                      <p className="text-SKF-G-002">
                        訂單狀態：
                        {/* {
                          paymentStatus.find((item) => item.value == order.status)
                            ?.label
                        } */}
                        <span className="font-bold">
                          {
                            orderStatus.find(
                              (item) => item.value == order.status
                            )?.label
                          }
                        </span>
                      </p>
                      {order.status == 32 && (
                        <p className="text-SKF-G-002">
                          支付訊息：
                          <span className="font-bold">
                            {order.message || "-"}
                          </span>
                        </p>
                      )}
                      <p className="text-SKF-G-002">
                        消費時間：
                        {convertTimeStamp(order.creationDate)}
                      </p>
                      <p className="text-SKF-G-002">訂單編號：{order.id}</p>
                      <p className="text-SKF-G-002">
                        付款方式：
                        {order.paymentMethod == 1 ? "信用卡" : "ATM虛擬帳號"}
                      </p>
                      <p className="text-SKF-G-002">
                        票券數量：
                        {order?.details?.length}
                      </p>
                    </Col>
                    {/* <Col
                      sm={{ span: 12 }}
                      xs={{ span: 6 }}
                      className="text-right"
                    >
                      <p>NT＄{order.price}</p>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Card className="[&_.ant-card-body]:p-0">
                        <Table
                          className="w-full"
                          columns={isMobile ? columns_mobile : columns}
                          dataSource={order.details}
                          pagination={false}
                          expandable={
                            isMobile
                              ? {
                                  expandedRowRender: (record) => (
                                    <>
                                      <p className="m-0">
                                        票券名稱：{record.title}
                                      </p>
                                      <p className="m-0">
                                        價格：{record.price}
                                      </p>
                                      <p className="m-0">
                                        參加者：{record.name}
                                      </p>
                                      <p className="m-0">Email{record.email}</p>
                                    </>
                                  ),
                                  rowExpandable: (record) =>
                                    record.name !== "Not Expandable",
                                }
                              : null
                          }
                        ></Table>
                      </Card>
                    </Col>
                  </Row>
                </Card>
              );
            })}
        </div>
      </Form>
      <Modal
        title="票券詳情"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <h6 className="bg-black text-white p-3 rounded-md my-2">
          {record.status !== 4 &&
            paymentStatus.find((item) => item.value == record.status)?.label}
        </h6>
        <p>活動名稱：{record.activityTitle}</p>
        <p>參加人/Name：{record.name}</p>
        <p>票號/Ticket Number：{record.id}</p>
        <p>票價/Price：{record.price}</p>
        <p>座位/Seat：自由選位</p>
        <p>票種/Ticket Type：{record.title}</p>
        <p>
          時間/Time：
          {`${convertTimeStamp(
            record.startDateTime,
            "MM/DD ddd - HH:mm"
          )}（opens at ${convertTimeStamp(
            record.effectiveStartDateTime,
            "HH:mm"
          )}）`}
        </p>
        <p>
          有效時間/Available：
          {convertTimeStamp(record.effectiveStartDateTime, "MM/DD ddd - HH:mm")}
          &nbsp; - &nbsp;
          {convertTimeStamp(record.effectiveEndDateTime, "MM/DD ddd - HH:mm")}
        </p>
        <p>
          活動地點/Location：
          <a href={record.locationUrl}>
            {record.location}
            {record.locationTitle !== "undefined" &&
              `(${record.locationTitle})`}
          </a>
        </p>

        {record?.status == 4 && (
          <>
            <p className="mt-6 mb-3">
              入場QR Code
              <img
                src={`data:image/jpeg;base64,${record.qrCode}`}
                className="w-1/4"
              />
            </p>
            <p>字軌：{record.taxSerialNumber}</p>
            <p>
              內容代徵娛樂稅 <br></br>
              本娛樂票券經{record.issuer}
              核准使用，並已向演出地之稅捐稽徵申請報繳娛樂稅
            </p>
          </>
        )}
      </Modal>
    </div>
  );
};

export default Member;
