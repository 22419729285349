import React, { useState, useEffect } from "react";
import activity_list from "mockUp/homepage.json";
import { Row, Col, Carousel, Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Fig84 from "assets/homepage/Frame84.png";
import Fig85 from "assets/homepage/Frame85.png";
import Fig86 from "assets/homepage/Frame86.png";
import Fig87 from "assets/homepage/Frame87.png";
import Logo3 from "assets/homepage/LOGO3.png";
import Logo4 from "assets/homepage/LOGO4.png";

import { convertTimeStamp } from "utility/functions/convertTimestamp";

import { apiGetActivityList } from "api/api";

const Homepage = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [limit, setLimit] = useState(100);
  const [offset, setOffset] = useState(0);
  const [rowData, setRowData] = useState([]);

  // # 獲取列表
  const fetchData = async ({ title, limit, offset }) => {
    try {
      const res = await apiGetActivityList({ title, limit, offset });
      const { content } = res.data;
      setRowData(content);
    } catch (err) {
    } finally {
    }
  };

  useEffect(() => {
    fetchData({ title, limit, offset });
  }, []);

  const handleToActivity = (item) => {
    navigate(`/activity/${item.id}`);
  };

  return (
    <div className="max-w-[1440px] mx-auto">
      {/* 頂部圖片 */}
      <Row className="h-full  px-0 md-20 desktop:mb-32">
        <Col
          span={24}
          className="h-[400px] desktop:h-[520px] rounded-lg w-full"
        >
          <Carousel autoplay>
            {rowData.map((item, i) => (
              <picture
                className="h-[400px] desktop:h-[520px]"
                key={i}
                onClick={() => handleToActivity(item)}
              >
                <source
                  media="(max-width: 520px)"
                  srcSet={encodeURI(item.mobileCover)}
                />
                <img
                  src={item.webCover}
                  className="w-full h-[400px] desktop:h-[520px] object-cover  desktop:rounded-3xl"
                />
              </picture>
            ))}
          </Carousel>
        </Col>
      </Row>

      <div className="px-0 desktop:px-40 tablet:px-10 ">
        {/* 活動列表 */}
        <div className="md-20 desktop:mb-32">
          {/* hover:scale-105  */}
          {rowData.map((item, i) => (
            <Row
              className="desktop:h-64 mt-10 my-8 mobile:px-2 cursor-pointer transition ease-in-out delay-100 hover:opacity-75  duration-200"
              key={i}
              onClick={() => handleToActivity(item)}
            >
              {/* 電影圖片 */}
              <Col
                sm={{ span: 12, offset: 0 }}
                xs={{ span: 24, offset: 0 }}
                className="rounded-l-3xl"
              >
                <picture>
                  <source
                    media="(max-width: 520px)"
                    srcSet={encodeURI(item.mobileCover)}
                  />
                  <img
                    src={item.webCover}
                    className="w-full h-64 object-cover  desktop:rounded-l-3xl tablet:rounded-l-3xl mobile:rounded-t-3xl"
                  />
                </picture>
              </Col>
              {/* 電影說明 */}
              <Col
                sm={{ span: 12 }}
                xs={{ span: 24 }}
                className="desktop:rounded-r-3xl tablet:rounded-r-3xl mobile:rounded-b-3xl bg-neutral-900 text-left desktop:pl-8 desktop:pt-4 mobile:px-8 mobile:pb-8 tablet:pl-4"
              >
                <h3 className="mb-0 tablet:text-xl mobile:text-xl desktop:text-2xl">
                  {item.title}
                </h3>
                <p className="text-SKF-G-002">
                  {convertTimeStamp(item.startDateTime)}
                </p>
                <div>
                  <Button
                    className="mt-8 tablet:mt-4"
                    onClick={() => handleToActivity(item)}
                  >
                    購票連結
                  </Button>
                </div>
              </Col>
            </Row>
          ))}
        </div>

        {/* 體驗wording */}
        <Row className="md-20 desktop:mb-32">
          <Col sm={{ span: 12, offset: 6 }}>
            <h3>
              獨特的體驗
              <span className="hidden desktop:inline-block desktop:ml-2">
                Sensational Experience
              </span>
            </h3>
            <p className="px-3 desktop:px-0 text-SKF-G-002">
              Skyline Film的無線耳機打破了了⼾外電
              影的種種限制。有了無線耳機，你可以
              更專注在電影的世界，不會被外在環境
              所⼲擾，更是解決噪音，且對周遭環境友善的放映方式。
              <span className="hidden desktop:inline-block">
                We take your film-watching experience to a different level. With
                personal wireless headphones and deck chairs, join us on the
                selected rooftops and venues to explore the sensational outdoor
                cinema experience!
              </span>
            </p>
          </Col>
        </Row>

        {/* 體驗icon */}
        <Row className="mt-12 md-20 desktop:mb-32 mx-0">
          <Col sm={6} xs={12} className="mb-4">
            <img src={Fig84} className="w-4/5 m-auto mb-2 desktop:mb-8" />
            <h4 className="m-0">獨立耳機體驗</h4>
            <h4 className="m-0 hidden desktop:block">
              Personal Wireless Headphones
            </h4>
          </Col>
          <Col sm={6} xs={12} className="mb-4">
            <img src={Fig85} className="w-4/5 m-auto mb-2 desktop:mb-8" />
            <h4 className="m-0">獨立躺椅</h4>
            <h4 className="m-0 hidden desktop:block">Wooden Deck Chairs</h4>
          </Col>
          <Col sm={6} xs={12} className="mb-4">
            <img src={Fig86} className="w-4/5 m-auto mb-2 desktop:mb-8" />
            <h4 className="m-0">享受城市星空夜景</h4>
            <h4 className="m-0 hidden desktop:block">City Views</h4>
          </Col>
          <Col sm={6} xs={12} className="mb-4">
            <img src={Fig87} className="w-4/5 m-auto mb-2 desktop:mb-8" />
            <h4 className="m-0">特選食物</h4>
            <h4 className="m-0 hidden desktop:block">
              Authentic Food & Drinks
            </h4>
          </Col>
        </Row>

        {/* 贊助廠商 */}
        {/* <Row className="text-center md-20 desktop:mb-32">
          <Col span={24} className="mb-4">
            <h3>
              <span>合作夥伴</span>
              <span className="hidden desktop:inline-block desktop:ml-2">Sponsors</span>
            </h3>
          </Col>
          <Col sm={{ span: 3, offset: 9 }} xs={{ span: 11, offset: 1 }}>
            <img src={Logo3} />
          </Col>
          <Col sm={{ span: 3 }} xs={{ span: 11 }}>
            <img src={Logo4} />
          </Col>
          <Col
            className="my-4"
            sm={{ span: 8, offset: 8 }}
            xs={{ span: 16, offset: 4 }}
          >
            <Button
              type="link"
              className="text-[#fff]"
              icon={<ArrowRightOutlined />}
              onClick={() => navigate("/corporation")}
            >
              想成為我們的年度合作夥伴？
            </Button>
          </Col>
        </Row> */}

        {/* 與我們合作 */}
        <Row className="desktop:mb-32 mobile:px-2 tablet:mt-10 mobile:mt-10">
          <Col
            sm={12}
            xs={24}
            className="min-h-96 desktop:min-h-[500px] bg-[url('assets/homepage/Corporation.png')] desktop:bg-[url('assets/homepage/Corporation.png')] bg-no-repeat bg-cover mobile:rounded-t-3xl desktop:rounded-l-3xl tablet:rounded-l-3xl"
          ></Col>
          <Col
            sm={12}
            xs={24}
            className="min-h-96 desktop:min-h-[500px] flex-col mobile:rounded-b-3xl desktop:rounded-r-3xl tablet:rounded-r-3xl bg-SKF-Green-001 text-black text-left px-8 py-11 mobile:py-4 desktop:pt-28"
          >
            <h3 className="mb-0 font-body">與我們合作Partnership</h3>
            <p className="text-SKF-G-003">
              透過成為我們活動的贊助夥伴，藉由屋頂電影院的實體活動及社群宣傳來進行品牌曝光。或是藉由屋頂團隊的專業團隊，輕鬆打造出專屬品牌、公司行號及VIP的客製化放映活動！
              <br />
              <br />
              快來一場屬於自己的放映體驗吧!
              <br />
              <br />
              You can easily be a part of our event sponsors to showcase your
              brands/services, or create your private rooftop events to treat
              your staff/customers/VIPs!
            </p>
            <div>
              <Button
                className="mt-8 bg-black text-white"
                onClick={() => navigate(`/corporation`)}
              >
                了解更多
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Homepage;
