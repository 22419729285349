import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Button, message } from "antd";
import { InputPassword, InputPlug } from "./style";
import { useOutletContext, useNavigate } from "react-router-dom";
import { apiRegister } from "api/api";

const Register = () => {
  const [info, success, warning, error] = useOutletContext(); // 從outlet傳過來的, 必須整個陣列帶入
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const isLogIn =
    sessionStorage._skyFilm_t && sessionStorage._skyFilm_t.length > 0;

  useEffect(() => {
    if (isLogIn) navigate(-1);
  }, []);

  const fetchRegister = async (data) => {
    setIsLoading(true);
    try {
      const res = await apiRegister(data);
      message.success("註冊成功");
      setTimeout(() => {
        navigate("/login?fromRegister=true");
      }, 1200);
    } catch (error) {
      message.error(error.response.data.message, 5);
    } finally {
      setIsLoading(false);
    }
  };

  const onFinish = (values) => {
    // success("註冊成功, 請重新登入!");
    delete values.email2;
    values.username = values.email;
    fetchRegister(values);
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    // error();
    console.log("Failed:", errorInfo);
  };

  return (
    <Row>
      <Col
        sm={{ span: 8, offset: 8 }}
        xs={{ span: 24 }}
        className="mobile:py-8 py-20 px-2 md:px-0"
      >
        <Card
          title={<h4 className="text-left">註冊Sign Up</h4>}
          extra={
            <a>
              <p className="text-right" onClick={() => navigate("/login")}>
                返回登入頁
              </p>
            </a>
          }
        >
          <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <Row gutter={16}>
              <Col sm={{ span: 24 }} xs={{ span: 24 }}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[{ required: true }]}
                >
                  <InputPlug size="large" className="p-2" placeholder="名字" />
                </Form.Item>
              </Col>
              {/* <Col sm={{ span: 12 }} xs={{ span: 24 }}>
                <Form.Item label="Last Name" name="last_name">
                  <InputPlug size="large" className="p-2" placeholder="姓氏" />
                </Form.Item>
              </Col> */}
              <Col span={24}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true },
                    {
                      type: "email",
                    },
                  ]}
                >
                  <InputPlug
                    size="large"
                    className="p-2"
                    placeholder="電子郵件 Email"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Confirm Email"
                  name="email2"
                  rules={[
                    { required: true },
                    {
                      type: "email",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("email") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("您輸入的Email與先前不同")
                        );
                      },
                    }),
                  ]}
                >
                  <InputPlug
                    size="large"
                    className="p-2"
                    placeholder="電子郵件 Email"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Mobile Phone"
                  name="phone"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      pattern: /^09[0-9]{8}$/,
                      message: "格式錯誤(format is wrong)",
                    },
                  ]}
                >
                  <InputPlug size="large" className="p-2" placeholder="電話" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Password"
                  name="password"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                    },
                    {
                      pattern:
                        /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d#?!@$%^&\-]{7,20}$/,
                      message: "密碼為7-20位數，須包含英文或特殊符號",
                    },
                  ]}
                >
                  <InputPassword
                    size="large"
                    className="p-2"
                    placeholder="密碼"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Confirm Password"
                  name="password2"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("您輸入的密碼與先前不同")
                        );
                      },
                    }),
                  ]}
                >
                  <InputPassword
                    size="large"
                    className="p-2"
                    placeholder="再次輸入"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="bg-SKF-B-001 w-full"
                loading={isLoading}
              >
                註冊
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default Register;
